
  import { HotspotState, RetailerState } from '@/store/types'
  import { Action, Getter } from 'vuex-class'
  import { Component, Vue, Watch } from 'vue-property-decorator'
  import {
    ModeProvider,
    StateProvider,
    ResizeProvider,
    PointerProvider,
    VisibilityProvider,
    MixerProvider,
    TrackingProvider,
  } from '@/providers'
  import Navigator from '@/components/organsims/Navigator.vue'
  import Hotspots from '@/components/organsims/Hotspots.vue'
  import Tutorial from '@/components/organsims/Tutorial.vue'
  import Welcome from '@/components/organsims/Welcome.vue'
  import Overlay from '@/components/organsims/Overlay.vue'
  import Portal from '@/components/organsims/Portal.vue'
  import Landing from '@/components/organsims/Landing.vue'
  import Header from '@/components/molecules/Header.vue'
  import Footer from '@/components/molecules/Footer.vue'
  import Loader from '@/components/organsims/Loader.vue'
  import Dolly from '@/components/organsims/Dolly.vue'
  import BaseScene from '@/webgl/scenes/base/Base'

  @Component({
    components: {
      ThreeProvider: () => import(/* webpackChunkName: "webgl" */ '@/webgl/providers/Three'),
      BedroomScene: () => import(/* webpackChunkName: "webgl" */ '@/webgl/scenes/bedroom/Bedroom.vue'),
      MakeupScene: () => import(/* webpackChunkName: "webgl" */ '@/webgl/scenes/makeup/Makeup.vue'),
      PorchScene: () => import(/* webpackChunkName: "webgl" */ '@/webgl/scenes/porch/Porch.vue'),
      Starlight: () => import(/* webpackChunkName: "webgl" */ '@/webgl/components/Starlight'),
      Sprinkles: () => import(/* webpackChunkName: "webgl" */ '@/webgl/components/Sprinkles'),
      Flowers: () => import(/* webpackChunkName: "webgl" */ '@/webgl/components/Flowers'),
      Lights: () => import(/* webpackChunkName: "webgl" */ '@/webgl/components/Lights'),
      Glow: () => import(/* webpackChunkName: "webgl" */ '@/webgl/components/Glow'),
      Canvas: () => import(/* webpackChunkName: "webgl" */ '@/webgl/Canvas.vue'),
      VisibilityProvider,
      TrackingProvider,
      PointerProvider,
      ResizeProvider,
      StateProvider,
      MixerProvider,
      ModeProvider,
      Navigator,
      Hotspots,
      Tutorial,
      Welcome,
      Overlay,
      Landing,
      Portal,
      Loader,
      Header,
      Footer,
      Dolly,
    },
  })
  export default class Root extends Vue {
    @Getter('hotspots')
    hotspots!: HotspotState[]

    @Getter('hotspot')
    currentHotspot!: HotspotState

    @Getter('retailer')
    currentRetailer!: RetailerState

    @Getter('scene')
    currentScene!: string

    @Getter('complete')
    complete!: boolean

    @Getter('started')
    started!: boolean

    @Getter('tutorial')
    tutorial!: boolean

    @Getter('welcome')
    welcome!: boolean

    @Getter('locale')
    locale!: boolean

    @Getter('portal')
    portal!: boolean

    @Getter('devcam')
    devcam!: boolean

    @Getter('debug')
    debug!: boolean

    @Action('fetch')
    fetch!: () => void

    @Action('finish')
    finish!: () => void

    @Action('start')
    starExperience!: () => void

    @Action('gotoNextScene')
    gotoNextScene!: () => void

    @Action('setScene')
    setScene!: (scene: string) => void

    @Action('setPortal')
    setPortal!: (portal: boolean) => void

    @Action('setWelcomeState')
    setWelcomeState!: () => void

    @Action('setTutorialState')
    setTutorialState!: () => void

    @Action('setCurrentHotspot')
    setCurrentHotspot!: (hotspot: HotspotState | null) => void

    sceneLoading = false
    sceneLanding = true

    $refs!: {
      scene: BaseScene
      loader: Loader
    }

    @Watch('currentScene', { immediate: true })
    onSceneChange() {
      this.sceneLoading = true
    }

    @Watch('started')
    async onStarted() {
      await this.$timer.defer(0.8)
      this.$refs.scene.reveal()
    }

    @Watch('tutorial')
    async onConfirm() {
      await this.$nextTick()
      this.sceneLanding = false
    }

    get sceneComponent() {
      const sceneComponent = `${this.$fn.capitalize(this.currentScene)}Scene`
      return this.currentScene.length > 0 ? sceneComponent : null
    }

    get active() {
      return !this.sceneLoading && this.started && this.welcome && this.tutorial
    }

    get needsLoader() {
      return this.complete && this.sceneLoading
    }

    get needsLanding() {
      return this.locale && !this.started
    }

    get needsWelcome() {
      return this.started && !this.welcome
    }

    get needsTutorial() {
      return this.started && this.welcome && !this.tutorial
    }
    get needsOverlay() {
      return this.currentHotspot !== null
    }

    get needsEffects() {
      return this.$gpu.getPP()
    }

    async sceneLeave(_: any, done: () => void) {
      await this.$refs.scene.unmount(done)
    }

    async sceneEnter(_: any, done: () => void) {
      await this.$refs.scene.mount(done, this.started)
      this.sceneLoading = false
    }

    async mounted() {
      await this.$timer.defer(2)
      await this.fetch()
      await this.$timer.defer(0.6)
      await this.finish()
    }
  }
