import { Vector2 } from 'three'
import { Component, Vue } from 'vue-property-decorator'

@Component
export class PointerProvider extends Vue {
  normalized = new Vector2(-1, -1)
  position = new Vector2(-1, -1)
  spreaded = new Vector2(-1, -1)
  velocity = new Vector2()
  delta = new Vector2()

  lastMouse = new Vector2()
  lastTime = undefined as any

  pointerUpdate(event: any) {
    event.preventDefault()

    const x = event.touches ? event.touches[0].clientX : event.clientX
    const y = event.touches ? event.touches[0].clientY : event.clientY

    const width = window.innerWidth
    const height = window.innerHeight

    this.position.x = x
    this.position.y = y

    this.normalized.x = x / width
    this.normalized.y = 1 - y / height

    this.spreaded.x = (x / width) * 2 - 1
    this.spreaded.y = -(y / height) * 2 + 1

    if (this.$device.mobile) {
      this.spreaded.x = 0
      //this.spreaded.y *= -1
    }

    // Calculate velocity
    if (!this.lastTime) {
      // First frame
      this.lastTime = performance.now()
      this.lastMouse.set(x, y)
    }

    this.delta.x = x - this.lastMouse.x
    this.delta.y = y - this.lastMouse.y

    this.lastMouse.set(x, y)

    const time = performance.now()

    // Avoid dividing by 0
    const delta = Math.max(10.4, time - this.lastTime)

    this.lastTime = time

    this.velocity.x = this.delta.x / delta
    this.velocity.y = this.delta.y / delta
  }

  mounted() {
    document.addEventListener('touchmove', this.pointerUpdate, this.$fn.passive())
    document.addEventListener('mousemove', this.pointerUpdate, this.$fn.passive())
  }

  destroyed() {
    document.removeEventListener('touchmove', this.pointerUpdate)
    document.removeEventListener('mousemove', this.pointerUpdate)
  }

  render() {
    return (
      !this.$scopedSlots.$hasNormal &&
      this.$scopedSlots.default &&
      this.$scopedSlots.default({
        normalized: this.normalized,
        spreaded: this.spreaded,
        coords: this.position,
        delta: this.delta,
      })
    )
  }
}
