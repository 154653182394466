import { settings } from '@/services/config'
import { isDefault } from '@/services/mode'
import { AppState } from './types'
import { Scenes } from '@/constants'

export default <AppState>{
  collection: {},
  retailers: {},
  progress: 0,
  settings,
  complete: false,
  tutorial: false,
  welcome: false,
  fetched: false,
  started: false,
  hotspot: null,
  portal: false,
  locale: false,
  scene: isDefault ? Scenes.BEDROOM : Scenes.BEDROOM,
  scenes: isDefault ? [Scenes.BEDROOM, Scenes.MAKEUP, Scenes.PORCH] : [Scenes.BEDROOM, Scenes.PORCH],
  router: {
    to: {},
    from: {},
  },
}
