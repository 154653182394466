
  import { Events } from '@/constants'
  import { Getter } from 'vuex-class'
  import { RetailerState } from '@/store/types'
  import { Component, Vue } from 'vue-property-decorator'
  import Loader from '@/components/atoms/Loader.vue'
  import Button from '@/components/atoms/Button.vue'
  import Logo from '@/components/atoms/Logo.vue'
  import gsap from 'gsap'

  @Component({
    components: {
      Loader,
      Button,
      Logo,
    },
  })
  export default class Landing extends Vue {
    @Getter('locale')
    locale!: boolean

    @Getter('fetched')
    fetched!: boolean

    @Getter('complete')
    complete!: boolean

    @Getter('progress')
    progressLoader!: number

    @Getter('retailer')
    retailer!: RetailerState

    progressClamp = 0.98

    needsStartCta = false

    get progress() {
      return this.progressLoader * this.progressClamp
    }

    async compiled() {
      this.progressClamp = 1
      await this.$timer.defer(0.3)
      this.needsStartCta = true
    }

    logoEnter($el: HTMLElement, done: () => void) {
      const $flowers = $el.querySelectorAll('.landing__heading .flowers')
      const $brand = $el.querySelectorAll('.landing__heading .logo .logo__brand path')
      const $name = $el.querySelectorAll('.landing__heading .logo .logo__name path')
      const $copy = $el.querySelectorAll('.landing__heading .logo .logo__copy path')

      gsap
        .timeline({ onComplete: done })
        .add(
          gsap.timeline().fromTo($flowers, { opacity: 0 }, { opacity: 1, duration: 0.6, ease: 'power2.inOut' }, '<'),
          '<'
        )
        .add(
          gsap
            .timeline()
            .fromTo(
              $brand,
              { opacity: 0, filter: `blur(${5}px)`, webkitFilter: `blur(${5}px)` },
              {
                opacity: 1,
                filter: `blur(${0}px)`,
                webkitFilter: `blur(${0}px)`,
                duration: 0.6,
                stagger: { amount: 0.8 },
                ease: 'power2.inOut',
              },
              '<'
            )
            .fromTo(
              $name,
              { opacity: 0, filter: `blur(${5}px)`, webkitFilter: `blur(${5}px)` },
              {
                opacity: 1,
                filter: `blur(${0}px)`,
                webkitFilter: `blur(${0}px)`,
                duration: 0.6,
                stagger: { amount: 0.8 },
                ease: 'power2.inOut',
              },
              '<+.1'
            )
            .fromTo(
              $copy,
              { opacity: 0, filter: `blur(${5}px)`, webkitFilter: `blur(${5}px)` },
              {
                opacity: 1,
                filter: `blur(${0}px)`,
                webkitFilter: `blur(${0}px)`,
                duration: 0.6,
                stagger: { amount: 0.8 },
                ease: 'power2.inOut',
              },
              '<+.1'
            ),
          '<+.2'
        )
    }

    logoLeave($el: HTMLElement, done: () => void) {
      done()
    }

    progressEnter($el: HTMLElement, done: () => void) {
      gsap
        .timeline({ delay: 0.8, onComplete: done })
        .from($el, { scale: 0.4, opacity: 0, duration: 0.8, ease: 'back.out' })
    }

    progressLeave($el: HTMLElement, done: () => void) {
      gsap.timeline({ onComplete: done }).to($el, { scale: 0.4, opacity: 0, duration: 1, ease: 'expo.inOut' })
    }

    buttonEnter($el: HTMLElement, done: () => void) {
      gsap
        .timeline({ delay: 0.6, onComplete: done })
        .from($el, { y: 40, skewY: 3, opacity: 0, duration: 1.6, ease: 'expo.out' })
    }

    buttonLeave($el: HTMLElement, done: () => void) {
      done()
    }

    messageEnter($el: HTMLElement, done: () => void) {
      const $message = $el.querySelectorAll('p span')

      gsap.timeline({ delay: 1, onComplete: done }).fromTo(
        $message,
        { y: 60, skewY: 10, opacity: 0, transformOrigin: 'left top' },
        {
          y: 0,
          skewY: 0,
          opacity: 1,
          transformOrigin: 'left top',
          duration: 0.8,
          stagger: { amount: 0.4 },
          ease: 'power2.out',
        }
      )
    }

    messageLeave($el: HTMLElement, done: () => void) {
      done()
    }

    leave($el: HTMLElement, done: () => void) {
      const $background = $el.querySelector('.landing__background')
      const $heading = $el.querySelectorAll('.landing__heading')
      const $display = $el.querySelectorAll('.landing__display')
      const $message = $el.querySelectorAll('.landing__message p span')

      /* const timeline =  */ gsap
        .timeline({ onComplete: done })
        .to($background, { opacity: 0, duration: 4, ease: 'power2.inOut' }, '<')
        .to($display, { opacity: 0, scale: 0.9, duration: 0.6, ease: 'power2.out' }, '<')
        .to($heading, { opacity: 0, y: -20, duration: 0.8, ease: 'power2.inOut' }, '<+.05')
        .to(
          $message,
          {
            y: 60,
            skewY: 10,
            opacity: 0,
            transformOrigin: 'left top',
            duration: 0.8,
            stagger: { amount: -0.2 },
            ease: 'power2.inOut',
          },
          '<+.1'
        )
      //.to($el, { opacity: 0, duration: .8, ease: 'power2.inOut' }, '<')
      // console.log(timeline.duration())
    }

    mounted() {
      this.$bus.$on(Events.GL.COMPILE, this.compiled)
    }

    destroyed() {
      this.$bus.$off(Events.GL.COMPILE, this.compiled)
    }
  }
