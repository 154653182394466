import { Action, Getter } from 'vuex-class'
import { SettingState } from '@/store/types'
import { Component, Vue } from 'vue-property-decorator'
import { Events } from '@/constants'

@Component
export class StateProvider extends Vue {
  @Getter('settings')
  settings!: SettingState

  @Action('syncSettings')
  syncSettings!: (settings: SettingState) => void

  update(scene: SettingState) {
    this.syncSettings(scene)
  }

  mounted() {
    this.$bus.$on(Events.GUI.CHANGE, this.update)
    //this.update(this.scene)
  }

  destroyed() {
    this.$bus.$off(Events.GUI.CHANGE, this.update)
  }

  render() {
    return (
      !this.$scopedSlots.$hasNormal &&
      this.$scopedSlots.default &&
      this.$scopedSlots.default({
        settings: this.settings,
      })
    )
  }
}
