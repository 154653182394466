
  import { Component } from 'vue-property-decorator'
  import Heading from '@/components/atoms/Heading.vue'
  import MediaWidget from './MediaWidget'

  @Component({
    components: {
      Heading,
    },
  })
  export default class AudioPlayer extends MediaWidget {
    $refs!: {
      audio: HTMLAudioElement
    }

    onPlay() {
      this.$emit('play')
    }

    onPause() {
      this.$emit('pause')
    }

    mounted() {
      this.$refs.audio.addEventListener('play', this.onPlay)
      this.$refs.audio.addEventListener('pause', this.onPause)
    }

    beforeDestroy() {
      this.$refs.audio.removeEventListener('pause', this.onPause)
      this.$refs.audio.removeEventListener('play', this.onPlay)
    }

    destroyed() {
      this.$emit('pause')
    }
  }
