export const settings = {
  lights: {
    ambient: {
      intensity: { value: 0.82, min: 0, max: 2, step: 0.001 },
      color: { value: '#ffffff' },
    },
    directional: {
      intensity: { value: 0.25, min: 0, max: 2, step: 0.001 },
      color: { value: '#ffffff' },
      positionX: { value: -0.026, min: -0.1, max: 0.1, step: 0.001 },
      positionY: { value: -0.008, min: -0.1, max: 0.1, step: 0.001 },
      positionZ: { value: 0.018, min: -0.1, max: 0.1, step: 0.001 },
      lookAtX: { value: -0.016, min: -0.1, max: 0.1, step: 0.001 },
      lookAtY: { value: -0.013, min: -0.1, max: 0.1, step: 0.001 },
      lookAtZ: { value: -0.002, min: -0.1, max: 0.1, step: 0.001 },
    },
  },

  clouds: {
    noise: {
      x: { value: 6, min: 0, max: 6, step: 0.001 },
      y: { value: 0.7, min: 0, max: 2, step: 0.001 },
      z: { value: 0.4, min: 0, max: 2, step: 0.001 },
      w: { value: 0.1, min: 0, max: 2, step: 0.001 },
    },
    offset: {
      x: { value: 0.008, min: -1, max: 1, step: 0.001 },
      y: { value: -0.086, min: -1, max: 1, step: 0.001 },
    },
  },

  water: {
    reflectivity: { value: 1.5, min: -2, max: 2, step: 0.001 },
    offset: {
      x: { value: -0.675, min: -10, max: 10, step: 0.001 },
      y: { value: -7, min: -10, max: 10, step: 0.001 },
    },
  },

  sprinkles: {
    position: {
      x: { value: 0, min: -0.01, max: 0.01, step: 0.001 },
      y: { value: 0, min: -0.01, max: 0.01, step: 0.001 },
      z: { value: 0, min: -0.01, max: 0.01, step: 0.001 },
    },
    reveal: { value: 0, min: 0, max: 1, step: 0.001 },
  },

  door: {
    noise: {
      x: { value: 4, min: -5, max: 5, step: 0.01 },
      y: { value: 2, min: -2, max: 2, step: 0.01 },
      z: { value: 1, min: -1, max: 1, step: 0.01 },
      w: { value: -0.5, min: -1, max: 1, step: 0.01 },
    },
    reveal: { value: 0, min: 0, max: 3.14, step: 0.001 },
  },

  glow: {
    strength: { value: 0.5, min: 0, max: 1, step: 0.001 },
    flare: {
      x: { value: 1, min: 0, max: 5, step: 0.01 },
      y: { value: 1, min: 0, max: 5, step: 0.01 },
    },
    ratio: {
      x: { value: 1, min: 0, max: 5, step: 0.01 },
      y: { value: 1, min: 0, max: 5, step: 0.01 },
    },
    reveal: { value: 0, min: 0, max: 1, step: 0.001 },
  },

  portal: {
    noise: {
      x: { value: 4, min: -5, max: 5, step: 0.01 },
      y: { value: 1.5, min: -2, max: 2, step: 0.01 },
      z: { value: 0.03, min: -1, max: 1, step: 0.01 },
      w: { value: 0.5, min: -1, max: 1, step: 0.01 },
    },
    ratio: {
      x: { value: 1, min: 0, max: 5, step: 0.01 },
      y: { value: 1, min: 0, max: 5, step: 0.01 },
    },
    reveal: { value: 0, min: 0, max: 1, step: 0.001 },
  },

  flowers: {
    translate: {
      x: { value: 0, min: -0.1, max: 0.1, step: 0.001 },
      y: { value: 0, min: -0.1, max: 0.1, step: 0.001 },
      z: { value: -0.04, min: -0.1, max: 0.1, step: 0.001 },
    },
    rotation: {
      x: { value: 0, min: -6.283, max: 6.283, step: 0.001 },
      y: { value: 0, min: -6.283, max: 6.283, step: 0.001 },
      z: { value: 0, min: -6.283, max: 6.283, step: 0.001 },
    },
    scale: { value: 0.001, min: 0, max: 0.1, step: 0.001 },
    reveal: { value: 0, min: 0, max: 1, step: 0.001 },
  },

  bloom: {
    threshold: { value: 0.94, min: 0, max: 1, step: 0.001 },
    strength: { value: 0.1, min: 0, max: 3, step: 0.001 },
    radius: { value: 0.2, min: 0, max: 1, step: 0.001 },
    mask: { value: 0.15, min: 0, max: 1, step: 0.001 },
    enabled: { value: true },
  },

  fluid: {
    iterations: { value: 2, min: 0, max: 8, step: 1 },
    densityDissipation: { value: 0.97, min: 0, max: 1, step: 0.001 },
    velocityDissipation: { value: 0.98, min: 0, max: 1, step: 0.001 },
    pressureDissipation: { value: 0.8, min: 0, max: 1, step: 0.001 },
    curlStrength: { value: 10, min: 0, max: 30, step: 1 },
    radius: { value: 0.03, min: 0, max: 1, step: 0.001 },
    flowMap: { value: false },
    enabled: { value: true },
  },

  noise: {
    angle: { value: 0, min: 0, max: 6.28318, step: 0.0001 },
    amount: { value: 0.0008, min: 0, max: 0.1, step: 0.0001 },
    grain: { value: 0.06, min: 0, max: 1, step: 0.001 },
    enabled: { value: true },
  },

  blur: {
    blurX: { value: 0.02, min: 0, max: 0.1, step: 0.001 },
    blurY: { value: 0.01, min: 0, max: 0.1, step: 0.001 },
    vignette: {
      offset: { value: 1.3, min: 0, max: 2, step: 0.001 },
      darkness: { value: 1, min: 0, max: 2, step: 0.001 },
    },
    reveal: { value: 0, min: 0, max: 1, step: 0.001 },
  },

  bounding: {
    bedroom: { size: 0.068, depth: -0.0294 },
    makeup: { size: 0.068, depth: -0.0288 },
    porch: { size: 0.05, depth: -0.015 },
  },

  sounds: [
    { name: 'sound-bedroom-environment', loop: true, volume: 1, playbackRate: 1 },
    { name: 'sound-makeup-environment', loop: true, volume: 1, playbackRate: 1 },
    { name: 'sound-porch-environment', loop: true, volume: 1, playbackRate: 1 },
    { name: 'sound-transition', loop: false, volume: 1, playbackRate: 1 },
    { name: 'sound-hotspot', loop: false, volume: 1, playbackRate: 1 },
  ],
}
