import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar'
import MotionPathPlugin from 'gsap/MotionPathPlugin'
import InertiaPlugin from 'gsap/InertiaPlugin'
import Draggable from 'gsap/Draggable'
import gsap from 'gsap'

// scrollbar
type Delta = {
  x: number
  y: number
}

class HorizontalScrollPlugin extends ScrollbarPlugin {
  static pluginName = 'horizontalScroll'

  transformDelta(delta: Delta, fromEvent: Event) {
    if (!/wheel/.test(fromEvent.type)) {
      return delta
    }

    const { x, y } = delta
    const { friction } = this.options

    return {
      y: 0,
      x: (Math.abs(x) > Math.abs(y) ? x : y) / friction,
      //x: Math.sign(x || y) * Math.sqrt(x*x + y*y),
    }
  }
}

Scrollbar.use(HorizontalScrollPlugin)

// gsap

const PrecisionPlugin = {
  name: 'precision',
  init(target: any, vars: any, tween: any, index: any, targets: any) {
    const data = this as any
    data.t = target
    let value
    for (const p in vars) {
      value = vars[p]
      typeof value === 'function' && (value = value.call(tween, index, target, targets))
      data.pt = { n: data.pt, p: p, s: target[p], c: value - target[p] }
      data._props.push(p)
    }
  },
  render(ratio: any, data: any) {
    let { pt } = data
    while (pt) {
      data.t[pt.p] = pt.s + pt.c * ratio
      pt = pt.n
    }
  },
}

gsap.registerPlugin(Draggable, InertiaPlugin, MotionPathPlugin, PrecisionPlugin)
