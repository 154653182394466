
  import { Vector2 } from 'three'
  import { HotspotState } from '@/store/types'
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import Heading from '@/components/atoms/Heading.vue'
  import gsap from 'gsap'

  @Component({
    components: {
      Heading,
    },
  })
  export default class Portal extends Vue {
    @Prop()
    hotspot!: HotspotState

    @Prop()
    viewport!: Vector2

    @Prop()
    scene!: string

    @Watch('viewport', { deep: true, immediate: true })
    async resize() {
      await this.$nextTick()
      console.log('resize')

      const { panel, iframe, iframeContainer } = this.$refs

      const selector = gsap.utils.selector(document)
      const bbox = selector('.overlay .panel')[0].getBoundingClientRect()

      const panelWidth = bbox.width
      const panelHeight = this.$device.mobile ? bbox.height : 620

      const width = this.$device.mobile ? panelWidth - 20 : 420
      const height = panelHeight - 20

      gsap.set(panel, { width: panelWidth, height: panelHeight })
      gsap.set(iframe, { width, height })
      gsap.set(iframeContainer, { width, height, marginTop: 10 })

      console.log('requestIframeSize', { width, height })
    }

    $refs!: {
      panel: HTMLElement
      heading: HTMLElement
      iframe: HTMLIFrameElement
      iframeContainer: HTMLElement
    }

    receiveMessage(event: any) {
      if (event.data.type === 'iframeSize') {
        const { width, height } = event.data.message
        this.adjustIframeSize(width, height)
      } else if (event.data.type === 'cameraFailed') {
        console.log('cameraFailed')
      } else if (event.data.type === 'closed') {
        //console.log('closed')
        this.$emit('back')
      } else if (event.data.eventCategory === 'Flora Looks Clicks') {
        //console.log('Flora Looks Clicks:', event.data)
        this.$emit('update', event.data)
      }
    }

    adjustIframeSize(width: string, height: string) {
      const { iframe, iframeContainer } = this.$refs
      iframeContainer.style.width = width
      iframeContainer.style.height = height
      iframe.style.width = width
      iframe.style.height = height
    }

    enter($el: HTMLElement, done: () => void) {
      const $panel = $el.querySelector('.panel')

      gsap
        .timeline({ delay: 0.4, onComplete: done })
        .fromTo($el, { opacity: 0 }, { opacity: 1, duration: 0.8, ease: 'power2.inOut' }, '<')
        .fromTo($panel, { y: 0, opacity: 0 }, { y: 0, opacity: 1, duration: 0.8, ease: 'power2.out' }, '<+.1')
    }

    leave($el: HTMLElement, done: () => void) {
      gsap.timeline({ onComplete: done }).to($el, { opacity: 0, duration: 0.6, ease: 'power2.out' })
    }

    created() {
      window.addEventListener('message', this.receiveMessage, false)
    }

    destroyed() {
      window.removeEventListener('message', this.receiveMessage)
    }
  }
