var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('transition',{attrs:{"css":false,"appear":true},on:{"enter":_vm.enter,"leave":_vm.leave}},[_c('div',{staticClass:"hotspots"},_vm._l((_vm.collectionMap),function(hotspot,index){return _c('div',{key:`${hotspot}-${index}`,ref:"hotspots",refInFor:true,staticClass:"hotspot",class:[{ active: _vm.collectionState[index].focus }, { next: 'next-scene' === hotspot.uid }],attrs:{"aria-label":_vm.$t(`scenes_${_vm.scene}_hotspots_${hotspot.uid}_title`)},on:{"click":function($event){return _vm.select(hotspot)}}},[_c('div',{ref:"icons",refInFor:true,staticClass:"icon"},[_c('div',{staticClass:"wrapper"})]),_c('div',{ref:"panels",refInFor:true,staticClass:"panel"},[_c('div',{staticClass:"wrapper"},[_c('h3',[_c('span',{domProps:{"innerHTML":_vm._s(
                _vm.$t(
                  `scenes_${_vm.scene}_hotspots_${hotspot.uid}_title${
                    'next-scene' === hotspot.uid && _vm.isVariant ? '_variant' : ''
                  }`
                )
              )}})]),('next-scene' !== hotspot.uid)?_c('p',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t(`shared_more`))}})]):_vm._e()])])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }