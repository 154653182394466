
  import { Component } from 'vue-property-decorator'
  import Heading from '@/components/atoms/Heading.vue'
  import DownloadIcon from '@/components/atoms/Download.vue'
  import MediaWidget from './MediaWidget'

  @Component({
    components: {
      Heading,
      DownloadIcon,
    },
  })
  export default class ImagePattern extends MediaWidget {}
