
  import Swiper, { Pagination } from 'swiper'
  import { Component, Watch } from 'vue-property-decorator'
  import PlayIcon from '@/components/atoms/PlayIcon.vue'
  import Heading from '@/components/atoms/Heading.vue'
  import MediaWidget from './MediaWidget'
  import gsap from 'gsap'

  Swiper.use([Pagination])

  @Component({
    components: {
      PlayIcon,
      Heading,
    },
  })
  export default class VideoCarousel extends MediaWidget {
    swiper!: Swiper

    $refs!: {
      swiper: HTMLElement
      medias: HTMLElement[]
      titles: HTMLElement[]
      pagination: HTMLElement
      videos: HTMLVideoElement[]
    }

    playing = false

    /* @Watch('muted')
  onMuted (muted: boolean) {
    const { activeIndex: index } = this.swiper
    const { videos } = this.$refs

    const $video = videos[index]
    $video.volume = ~~muted
  } */

    @Watch('visible')
    onVisibility(visible: boolean) {
      const { activeIndex: index } = this.swiper
      const { videos } = this.$refs

      const $video = videos[index]

      if (!visible) {
        this.playing = false
        $video.pause()
        this.$emit('pause')
      }
    }

    togglePlay() {
      const { activeIndex: index } = this.swiper
      const { videos } = this.$refs
      const { gtm } = this.media[index]

      const $video = videos[index]

      if ($video.paused) {
        this.playing = true
        //$video.volume = ~~!this.muted
        $video.play()
        this.$emit('play', gtm)
      } else {
        this.playing = false
        $video.pause()
        this.$emit('pause')
      }
    }

    async resize() {
      await this.$nextTick()

      const { activeIndex: index } = this.swiper
      const { titles, medias, pagination } = this.$refs

      const title = titles[index]
      const media = medias[index]

      const initialScale = 1

      const { height: titleHeight } = title.getBoundingClientRect()
      const { height: mediaHeight } = media.getBoundingClientRect()

      const top = (titleHeight + mediaHeight) / initialScale

      gsap.set(pagination, { top })
    }

    videoExitedFullscreen() {
      const doc = document as any
      const isFullscreen =
        doc.fullScreenElement || doc.webkitIsFullScreen == true || doc.mozFullScreen || doc.msFullscreenElement
      this.playing = isFullscreen
      for (const video of this.$refs.videos) {
        video.load()
      }
    }

    mounted() {
      const { swiper, videos, pagination } = this.$refs

      this.swiper = new Swiper(swiper, {
        pagination: {
          el: pagination,
          clickable: true,
        },
        on: {
          slideChange: ({ previousIndex }) => {
            const $video = videos[previousIndex]
            if (!$video.paused) {
              this.playing = false
              this.$emit('pause')
              $video.pause()
              $video.load()
            }
          },
        },
      })

      //https://stackoverflow.com/questions/32228024/how-to-detect-ios-leaving-fullscreen-video
      if ('ios' === this.$device.system.os) {
        for (const video of videos) {
          video.addEventListener('webkitendfullscreen', this.videoExitedFullscreen, false)
        }
      }
    }

    destroyed() {
      this.swiper.destroy(true, false)
      this.$emit('pause')
    }
  }
