
  import { Component, Vue, Prop } from 'vue-property-decorator'

  @Component
  export default class LinkBtn extends Vue {
    @Prop()
    href!: string

    @Prop({ default: '_blank' })
    target!: string
  }
