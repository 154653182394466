
  import { gsap } from 'gsap'
  import { Component, Vue } from 'vue-property-decorator'
  import { WELCOME_DURATION, REVEAL_DURATION } from '@/constants'
  import SplitText from '@/components/atoms/SplitText.vue'
  import Heading from '@/components/atoms/Heading.vue'

  @Component({
    components: {
      Heading,
      SplitText,
    },
  })
  export default class Welcome extends Vue {
    $refs!: {
      split: SplitText
    }

    timer!: gsap.core.Tween

    enter($el: HTMLElement, done: () => void) {
      const { chars: $chars } = this.$refs.split
      const $heading = $el.querySelector('.welcome__heading h3 span')
      //const $title = $el.querySelectorAll('.welcome__title .char')

      gsap
        .timeline({
          delay: REVEAL_DURATION,
          onComplete: () => {
            this.start()
            done()
          },
        })
        .fromTo($el, { opacity: 0 }, { opacity: 1, duration: 1.6, ease: 'power2.out' })
        .fromTo(
          gsap.utils.shuffle($chars),
          { scale: 1.5, opacity: 0, filter: `blur(${5}px)`, webkitFilter: `blur(${5}px)` },
          {
            scale: 1,
            opacity: 1,
            filter: `blur(${0}px)`,
            webkitFilter: `blur(${0}px)`,
            duration: 1,
            stagger: { amount: 0.8 },
            ease: 'power2.out',
          },
          '<+.4'
        )
        .fromTo(
          $heading,
          { yPercent: 120, opacity: 0 },
          { yPercent: 0, opacity: 1, duration: 0.8, ease: 'power2.out' },
          '<+.2'
        )
    }

    leave($el: HTMLElement, done: () => void) {
      const { chars: $chars } = this.$refs.split
      const $background = $el.querySelector('.welcome__background')
      const $heading = $el.querySelector('.welcome__heading h3 span')

      gsap.set($background, { opacity: 0 })

      gsap
        .timeline({ onComplete: done })
        .to($heading, { yPercent: -120, opacity: 0, duration: 0.8, ease: 'power2.inOut' }, '<')
        .to(
          gsap.utils.shuffle($chars),
          {
            scale: 0.8,
            opacity: 0,
            filter: `blur(${5}px)`,
            webkitFilter: `blur(${5}px)`,
            duration: 0.6,
            stagger: { amount: 0.4 },
            ease: 'power2.inOut',
          },
          '<'
        )
    }

    start() {
      this.timer = gsap.delayedCall(WELCOME_DURATION, () => this.$emit('elapsed'))
    }

    skip() {
      if (!this.timer) return

      this.timer.kill()

      this.$emit('elapsed')
    }
  }
