
  import { Component, Vue } from 'vue-property-decorator'
  import { gsap } from 'gsap'

  @Component
  export default class DragIcon extends Vue {
    timeline!: gsap.core.Timeline

    $refs!: {
      icon: HTMLElement
    }

    mounted() {
      const $el = this.$el
      const { icon: $icon } = this.$refs
      this.timeline = gsap
        .timeline({ repeat: -1 })
        .add(
          gsap
            .timeline({ defaults: { duration: 1.2, ease: 'power2.inOut' } })
            .fromTo(
              $el,
              { scaleX: 1, scaleY: 1, x: -120 },
              {
                motionPath: {
                  curviness: 0,
                  path: [
                    { scaleX: 1, scaleY: 1, x: -120 },
                    { scaleX: 1.2, scaleY: 0.8, x: 0 },
                    { scaleX: 1, scaleY: 1, x: 120 },
                  ],
                },
              },
              '<'
            )
            .fromTo(
              $icon,
              { scaleX: 1, scaleY: 1, x: 0 },
              {
                motionPath: {
                  curviness: 0,
                  path: [
                    { scaleX: 1, scaleY: 1, x: 0 },
                    { scaleX: 0.9, scaleY: 0.9, x: 10 },
                    { scaleX: 1, scaleY: 1, x: 0 },
                  ],
                },
              },
              '<'
            )
        )
        .add(
          gsap
            .timeline({ defaults: { duration: 1.2, ease: 'power2.inOut' } })
            .to(
              $el,
              {
                motionPath: {
                  curviness: 0,
                  path: [
                    { scaleX: 1, scaleY: 1, x: 120 },
                    { scaleX: 1.2, scaleY: 0.7, x: 0 },
                    { scaleX: 1, scaleY: 1, x: -120 },
                  ],
                },
              },
              '<'
            )
            .to(
              $icon,
              {
                motionPath: {
                  curviness: 0,
                  path: [
                    { scaleX: 1, scaleY: 1, x: 0 },
                    { scaleX: 0.8, scaleY: 0.6, x: -10 },
                    { scaleX: 1, scaleY: 1, x: 0 },
                  ],
                },
              },
              '<'
            )
        )
    }

    destroyed() {
      if (this.timeline) this.timeline.kill()
    }
  }
