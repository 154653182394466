
  import { Getter } from 'vuex-class'
  import { SettingState } from '@/store/types'
  import { Component, Vue } from 'vue-property-decorator'
  import i18n from '@/i18n'

  @Component({
    components: {
      Stats: () => import(/* webpackChunkName: "debug" */ '@/components/molecules/dat/Stats.vue'),
      Specs: () => import(/* webpackChunkName: "debug" */ '@/components/molecules/dat/Specs.vue'),
      Gui: () => import(/* webpackChunkName: "debug" */ '@/components/molecules/dat/Gui.vue'),
    },
  })
  export default class App extends Vue {
    @Getter('settings')
    settings!: SettingState

    @Getter('scene')
    currentScene!: string

    @Getter('stats')
    stats!: boolean

    @Getter('specs')
    specs!: boolean

    @Getter('gui')
    gui!: boolean

    get pointer() {
      return this.$device.mobile ? 'touch' : 'no-touch'
    }

    get direction() {
      return 'ar' === i18n.locale ? 'rtl' : 'ltr'
    }

    get scene() {
      return this.currentScene
    }

    get needsStats() {
      return this.stats
    }

    get needsSpecs() {
      return this.specs
    }

    get needsGui() {
      return this.gui
    }
  }
