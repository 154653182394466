
  import { gsap } from 'gsap'
  import { HotspotState } from '@/store/types'
  import { PerspectiveCamera, Vector2 } from 'three'
  import { AppMode, Events, ENTER_DURATION, Scenes } from '@/constants'
  import { Component, Vue, Prop, Inject } from 'vue-property-decorator'

  @Component
  export default class Hotspots extends Vue {
    @Prop()
    collection!: HotspotState[]

    @Prop()
    viewport!: Vector2

    @Prop()
    prevent!: boolean

    @Prop()
    landing!: boolean

    @Prop()
    scene!: string

    @Prop()
    mouse!: Vector2

    @Prop()
    mode!: string

    @Inject()
    camera!: PerspectiveCamera

    enabled = false

    collectionMap =
      this.mode === AppMode.CHINA ? this.collection.filter((hotspot) => !hotspot.hiddenOnChina) : this.collection

    collectionState = this.collection.map(() => ({
      focus: false,
    }))

    $el!: HTMLElement

    $refs!: {
      hotspots: HTMLElement[]
      panels: HTMLElement[]
      icons: HTMLElement[]
    }

    snapOffset = new Vector2()

    offset = new Vector2()

    threshold = 80

    get preventSnap() {
      return !!this.$device.mobile || this.prevent
    }

    get isVariant() {
      return (
        (AppMode.VARIANT === this.mode || AppMode.CHINA === this.mode || AppMode.MACYS === this.mode) &&
        Scenes.BEDROOM === this.scene
      )
    }

    select(hotspot: HotspotState) {
      this.clear()

      // console.log({hotspot})

      if ('next-scene' !== hotspot.uid) {
        this.$emit('select', hotspot)
      } else {
        this.$emit('next')
      }
    }

    clear() {
      for (const state of this.collectionState) {
        state.focus = false
      }
    }

    tick() {
      const { hotspots, icons, panels } = this.$refs

      for (let i = 0; i < this.collectionMap.length; ++i) {
        const hotspot = this.collectionMap[i]

        const $hotspot = hotspots[i]

        gsap.set($hotspot, {
          x: hotspot.projection.x,
          y: hotspot.projection.y,
          force3D: true,
        })

        if (this.enabled && !this.preventSnap) {
          const $panel = panels[i]
          const $icon = icons[i]

          this.offset.x = hotspot.projection.x + this.viewport.x * 0.5
          this.offset.y = hotspot.projection.y + this.viewport.y * 0.5

          const distance = this.mouse.distanceTo(this.offset)

          this.collectionState[i].focus = distance < this.threshold

          this.snapOffset.set(0, 0)

          if (distance < this.threshold) {
            this.snapOffset.x = (this.mouse.x - this.offset.x) * 0.8
            this.snapOffset.y = (this.mouse.y - this.offset.y) * 0.8
          }

          gsap.to([$icon, $panel], {
            duration: 1,
            ease: 'power2.out',
            x: this.snapOffset.x,
            y: this.snapOffset.y,
          })
        }
      }
    }

    enter($el: HTMLElement, done: () => void) {
      const $icons = $el.querySelectorAll('.icon')
      const $panels = $el.querySelectorAll('.panel')

      const delay = this.landing ? 0.6 : ENTER_DURATION

      gsap
        .timeline({
          delay,
          onComplete: () => {
            this.enabled = true
            done()
          },
        })
        .fromTo($icons, { scale: 0 }, { scale: 1, duration: 1.2, stagger: 0.12, ease: 'power2.inOut' }, '<')
        .fromTo($panels, { opacity: 0 }, { opacity: 1, duration: 1.2, stagger: 0.12, ease: 'power2.inOut' }, '<')
    }

    leave($el: HTMLElement, done: () => void) {
      const $icons = $el.querySelectorAll('.icon')
      const $panels = $el.querySelectorAll('.panel')

      gsap
        .timeline({ onComplete: done })
        .to($icons, { scale: 0, duration: 0.6, ease: 'expo.out' }, '<')
        .to($panels, { opacity: 0, duration: 0.6, ease: 'expo.out' }, '<')
    }

    setup() {
      const { icons, panels } = this.$refs
      gsap.set(panels, { opacity: 0 })
      gsap.set(icons, { scale: 0 })
    }

    mounted() {
      this.$bus.$on(Events.GL.RENDER, this.tick)
      this.setup()
    }

    destroyed() {
      this.$bus.$off(Events.GL.RENDER, this.tick)
    }
  }
