import WebFont from 'webfontloader'

const base = process.env.BASE_URL

/**
 * chinese fonts
 */
const zhConfig = {
  google: { families: ['Noto Sans SC:300,400,700'] },
}

/**
 * japanese fonts
 */
const jaConfig = {
  google: { families: ['Noto Sans JP:300,400,700'] },
}

/**
 * latin fonts
 */
const ltConfig = {
  custom: {
    urls: [`${base}fonts/GucciFonts.css`],
    families: ['GucciSerif10-Regular', 'GucciSansPro-Medium', 'GucciSansPro-Light'],
  },
}

export const fetchWebFont = (locale: string) => {
  return new Promise((resolve) => {
    const config = 'zh' === locale ? zhConfig : 'ja' === locale ? jaConfig : ltConfig

    WebFont.load({
      active: resolve as () => void,
      classes: false,
      ...config,
    })
  })
}
