import { Component, Vue } from 'vue-property-decorator'
import { Scenes } from '@/constants'

interface GTMEvent {
  eventCategory: string
  eventAction?: string
  eventLabel?: string
}

const dataLayer = (<any>window)['dataLayer']

export const GTMEventCategory = {
  ENVIRONMENT_MENU: 'Environment Quick Menu',
  START_EXPLORING: 'Clicks on "Start Exploring"',
  COOKIE_PRIVACY: 'Cookie & Privacy',
  TOGGL_AUDIO: 'Audio',
  SHOP_FLORA: 'Clicks on "Shop Now" Flora',
  PAGEVIEW: 'Scan QR Code',
  DOORS: 'Clicks on Doors',
}

export const GTMEventAction = {
  COOKIE: 'Clicks on Cookies',
  PRIVACY: 'Clicks on Privacy Policy',
  AUDIO_ON: 'on',
  AUDIO_OFF: 'off',
  SHOP: 'Clicks on "Shop Now"',
  BACK: 'Keep Exploring',
  VTO: 'Clicks on "Try now" for VTO',
  LAUNCH_IG: 'Clicks on "Start"',
  DOWNLOAD: 'Clicks on "Download"',
  PLAY: 'Clicks on "Play"',
}

export const GTMScenes = {
  [Scenes.BEDROOM]: 'The Room',
  [Scenes.MAKEUP]: 'The Make-Up Station',
  [Scenes.PORCH]: 'The Porch',
}

export const eventPush = (event: GTMEvent) => {
  dataLayer && dataLayer.push({ ...event, event: 'generic_event' })
  //console.log({ ...event, event: 'generic_event' })
}

@Component
export class TrackingProvider extends Vue {
  render() {
    return (
      !this.$scopedSlots.$hasNormal &&
      this.$scopedSlots.default &&
      this.$scopedSlots.default({
        GTMEventCategory,
        GTMEventAction,
        GTMScenes,
        eventPush,
      })
    )
  }
}
