export const live = () => {
  const { VUE_APP_LIVE_DATE, VUE_APP_LIVE_COUNTDOWN_URL, VUE_APP_LIVE_COUNTDOWN_ENABLE } = process.env

  const live = new Date(VUE_APP_LIVE_DATE as string)

  const liveUTC = Date.UTC(
    live.getFullYear(),
    live.getMonth(),
    live.getDate(),
    live.getHours(),
    live.getMinutes(),
    live.getSeconds(),
    live.getMilliseconds()
  )

  const now = new Date()
  const nowUTC = Date.UTC(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    now.getHours(),
    now.getMinutes(),
    now.getSeconds(),
    now.getMilliseconds()
  )

  const timeLeft = liveUTC - nowUTC
  // console.log({timeLeft})

  if (VUE_APP_LIVE_COUNTDOWN_ENABLE === 'true' && timeLeft > 0) {
    window.location.replace(VUE_APP_LIVE_COUNTDOWN_URL as string)
  } else return true
}
