const utmParams: any = {
  utm_campaign: 'florafantasy_website',
  utm_content: 'florafantasy_website',
  utm_term: 'florafantasy_website',
}

const utmWebParams: any = {
  ...utmParams,
  utm_medium: 'website',
  utm_source: 'florafantasy_website',
}

const utmAppParams: any = {
  ...utmParams,
  utm_medium: 'app',
  utm_source: 'florafantasy_gucciapp',
}

export const getUTMParams = (native: boolean) => {
  const params = native ? utmAppParams : utmWebParams

  let stringParams = ''
  for (const key in params) {
    const param = params[key]
    stringParams += `${key}=${param}&`
  }

  return stringParams.slice(0, -1)
}
