import { Component, Vue } from 'vue-property-decorator'

@Component
export class VisibilityProvider extends Vue {
  visible = true

  hidden!: string

  visibilityState!: string

  visibilityChange!: string

  created() {
    if (typeof (document as any).hidden !== undefined) {
      this.hidden = 'hidden'
      this.visibilityChange = 'visibilitychange'
      this.visibilityState = 'visibilityState'
    } else if (typeof (document as any).mozHidden !== undefined) {
      this.hidden = 'mozHidden'
      this.visibilityChange = 'mozvisibilitychange'
      this.visibilityState = 'mozVisibilityState'
    } else if (typeof (document as any).msHidden !== undefined) {
      this.hidden = 'msHidden'
      this.visibilityChange = 'msvisibilitychange'
      this.visibilityState = 'msVisibilityState'
    } else if (typeof (document as any).webkitHidden !== undefined) {
      this.hidden = 'webkitHidden'
      this.visibilityChange = 'webkitvisibilitychange'
      this.visibilityState = 'webkitVisibilityState'
    }

    if (typeof document.addEventListener === undefined || typeof this.hidden === undefined) {
      return
    } else {
      document.addEventListener(
        this.visibilityChange,
        () => {
          switch ((document as any)[this.visibilityState]) {
            case 'visible':
              this.visible = true
              this.$emit('visible')
              break
            case 'hidden':
              this.visible = false
              this.$emit('hidden')
              break
          }
        },
        this.$fn.passive()
      )
    }
  }

  render() {
    return (
      !this.$scopedSlots.$hasNormal &&
      this.$scopedSlots.default &&
      this.$scopedSlots.default({
        visible: this.visible,
      })
    )
  }
}
