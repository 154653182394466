import Vue from 'vue'
import Stats from 'stats.js'
import state from './modules/state'
import * as dat from 'dat.gui'
import * as fn from './modules/fn'
import * as gpu from './modules/gpu'
import * as timer from './modules/timer'
import * as device from './modules/device'

Vue.use(() => (Vue.prototype.$gui = new dat.GUI({ width: 280, autoPlace: false, closed: true })))
Vue.use(() => (Vue.prototype.$bus = new Vue()))
Vue.use(() => (Vue.prototype.$stats = new Stats()))
Vue.use(() => (Vue.prototype.$window = window as any))
Vue.use(() => (Vue.prototype.$device = device))
Vue.use(() => (Vue.prototype.$state = state))
Vue.use(() => (Vue.prototype.$timer = timer))
Vue.use(() => (Vue.prototype.$gpu = gpu))
Vue.use(() => (Vue.prototype.$fn = fn))
Vue.use(() => (Vue.prototype.$gl = {}))
