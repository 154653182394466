//import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import {
  LoadingManager,
  TextureLoader,
  sRGBEncoding,
  RepeatWrapping,
  EquirectangularReflectionMapping,
  HalfFloatType,
  AudioLoader,
  LinearFilter,
} from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { Scenes } from '@/constants'
import * as cache from '@/services/cache'
import store from '@/store'

const { BASE_URL: base } = process.env

const manager = new LoadingManager()

//const dracoLoader = new DRACOLoader(manager)
//dracoLoader.setDecoderPath(`${base}draco`)

const gltfLoader = new GLTFLoader(manager)
//gltfLoader.setDRACOLoader(dracoLoader)

const textureLoader = new TextureLoader(manager)

const audioLoader = new AudioLoader(manager)

const fetchSharedAssets = () => {
  const sharedCache = `shared-cache`

  cache.add(sharedCache, {})

  // textures
  ;[{ key: 'map-env', source: `${base}img/textures/maps/env-map.jpg` }].map(({ key, source }: any) => {
    textureLoader.load(source, (texture) => {
      texture.mapping = EquirectangularReflectionMapping
      texture.encoding = sRGBEncoding
      cache.get(sharedCache)[key] = texture
    })
  })
  ;[
    { key: 'map-leaf', source: `${base}img/textures/maps/leaf-map.png` },
    { key: 'map-flower-1', source: `${base}img/textures/flowers/01.png` },
    { key: 'map-flower-2', source: `${base}img/textures/flowers/02.png` },
    { key: 'map-flower-3', source: `${base}img/textures/flowers/03.png` },
    { key: 'map-flower-4', source: `${base}img/textures/flowers/04.png` },
    { key: 'map-flower-5', source: `${base}img/textures/flowers/05.png` },
    { key: 'map-flower-6', source: `${base}img/textures/flowers/06.png` },
    { key: 'map-flower-7', source: `${base}img/textures/flowers/07.png` },
    { key: 'map-flower-8', source: `${base}img/textures/flowers/08.png` },
  ].map(({ key, source }: any) => {
    textureLoader.load(source, (texture) => {
      texture.encoding = sRGBEncoding
      cache.get(sharedCache)[key] = texture
    })
  })
  ;[
    { key: 'map-cloud-1', source: `${base}img/textures/maps/front_cloud.png` },
    { key: 'map-cloud-2', source: `${base}img/textures/maps/back_cloud.png` },
    { key: 'sprite-cat-tail-cat-bedroom', source: `${base}img/textures/sprites/cat-tail-cat-bedroom-sprite.png` },
    { key: 'sprite-cats', source: `${base}img/textures/sprites/cats-sprite.png` },
  ].map(({ key, source }: any) => {
    textureLoader.load(source, (texture) => {
      texture.wrapS = texture.wrapT = RepeatWrapping
      texture.encoding = sRGBEncoding
      texture.type = HalfFloatType
      texture.flipY = false
      cache.get(sharedCache)[key] = texture
    })
  })

  // sounds
  ;[
    { key: 'sound-bedroom-environment', source: `${base}audio/1-Sound_Flora_Room.mp3` },
    { key: 'sound-makeup-environment', source: `${base}audio/2-Sound_Flora_MakeUpStation.mp3` },
    { key: 'sound-porch-environment', source: `${base}audio/3-Sound_Flora_Porch.mp3` },
    { key: 'sound-transition', source: `${base}audio/Transizione.mp3` },
    { key: 'sound-hotspot', source: `${base}audio/Tap.mp3` },
  ].map(({ key, source }: any) => {
    audioLoader.load(source, (buffer) => {
      cache.get(sharedCache)[key] = buffer
    })
  })
}

const fetchBedroomSceneAssets = () => {
  const sceneCache = `${Scenes.BEDROOM}-cache`

  cache.add(sceneCache, {})

  // environment
  ;[{ key: 'gltf-environment', source: `${base}gltf/bedroom/scena_env_1_scale_Maya_wip_10.gltf` }].map(
    ({ key, source }: any) => {
      gltfLoader.load(source, ({ scene }) => {
        cache.get(sceneCache)[key] = scene
      })
    }
  )

  // sprites
  ;[
    { key: 'sprite-bedroom-magnolia', source: `${base}img/textures/sprites/magnolia-sprite.png` },
    { key: 'sprite-bedroom-mandarin', source: `${base}img/textures/sprites/mandarin-sprite.png` },
    { key: 'sprite-bedroom-jasmine', source: `${base}img/textures/sprites/jasmine-sprite.png` },
    { key: 'sprite-bedroom-red-cat', source: `${base}img/textures/sprites/red-cat-sprite.png` },
    { key: 'sprite-bedroom-dog-eyes', source: `${base}img/textures/sprites/dog-eyes-sprite.png` },
    { key: 'sprite-bedroom-cat-carpet', source: `${base}img/textures/sprites/cat-room-carpet-sprite.png` },
  ].map(({ key, source }: any) => {
    textureLoader.load(source, (texture) => {
      texture.wrapS = texture.wrapT = RepeatWrapping
      texture.minFilter = LinearFilter
      texture.encoding = sRGBEncoding
      texture.type = HalfFloatType
      texture.flipY = false
      cache.get(sceneCache)[key] = texture
    })
  })
}

const fetchMakeupSceneAssets = () => {
  const sceneCache = `${Scenes.MAKEUP}-cache`

  cache.add(sceneCache, {})

  // environment
  ;[{ key: 'gltf-environment', source: `${base}gltf/makeup/scena_env_2_wip_5.gltf` }].map(({ key, source }: any) => {
    gltfLoader.load(source, ({ scene }) => {
      cache.get(sceneCache)[key] = scene
    })
  })

  // sprites
  ;[
    { key: 'sprite-makeup-cat-tail-drop', source: `${base}img/textures/sprites/cat-tail-drop-sprite.png` },
    {
      key: 'sprite-makeup-cat-carpet-cat-tail',
      source: `${base}img/textures/sprites/cat-carpet-cat-tail-sprite.png`,
    },
  ].map(({ key, source }: any) => {
    textureLoader.load(source, (texture) => {
      texture.wrapS = texture.wrapT = RepeatWrapping
      texture.minFilter = LinearFilter
      texture.encoding = sRGBEncoding
      texture.type = HalfFloatType
      texture.flipY = false
      cache.get(sceneCache)[key] = texture
    })
  })
}

const fetchPorchSceneAssets = () => {
  const sceneCache = `${Scenes.PORCH}-cache`

  cache.add(sceneCache, {})

  // environment
  ;[{ key: 'gltf-environment', source: `${base}gltf/porch/scena_env_3_wip_9.gltf` }].map(({ key, source }: any) => {
    gltfLoader.load(source, ({ scene }) => {
      cache.get(sceneCache)[key] = scene
    })
  })

  // sprites
  ;[
    {
      key: 'sprite-porch-jasmine-white-gardenia',
      source: `${base}img/textures/sprites/jasmine-white-gardenia-sprite.png`,
    },
    { key: 'sprite-porch-pear-blossom-accord', source: `${base}img/textures/sprites/pear-blossom-accord-sprite.png` },
    { key: 'sprite-porch-cat-ear', source: `${base}img/textures/sprites/cat-ear-sprite.png` },
    { key: 'sprite-porch-dog', source: `${base}img/textures/sprites/dog-sprite.png` },
  ].map(({ key, source }: any) => {
    textureLoader.load(source, (texture) => {
      texture.wrapS = texture.wrapT = RepeatWrapping
      texture.minFilter = LinearFilter
      texture.encoding = sRGBEncoding
      texture.type = HalfFloatType
      texture.flipY = false
      cache.get(sceneCache)[key] = texture
    })
  })
}

export const fetchSceneAssets = (scene?: string) => {
  switch (scene) {
    case Scenes.BEDROOM:
      fetchBedroomSceneAssets()
      break
    case Scenes.MAKEUP:
      fetchMakeupSceneAssets()
      break
    case Scenes.PORCH:
      fetchPorchSceneAssets()
      break
  }

  if (!cache.get('sharedCache')) {
    fetchSharedAssets()
  }

  return new Promise<void>((resolve) => {
    manager.onProgress = (_, loaded, total) => store.dispatch('progress', loaded / total)
    manager.onLoad = () => resolve()
  })
}
