
  import { Vector2 } from 'three'
  import { Events } from '@/constants'
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import Scrollbar from 'smooth-scrollbar'
  import Draggable from 'gsap/Draggable'
  import gsap from 'gsap'

  @Component
  export default class Dolly extends Vue {
    @Prop()
    snap!: number

    @Prop()
    bounding!: any

    @Prop()
    viewport!: Vector2

    scrollbar!: Scrollbar

    draggable!: Draggable

    size = new Vector2()

    $el!: HTMLElement

    @Watch('snap')
    snapTo(snap: number | null) {
      if (snap !== null) {
        const tweener = { x: this.scrollbar.offset.x }
        gsap.timeline({ defaults: { duration: 2, ease: 'expo.out' } }).to(tweener, {
          x: snap,
          onUpdate: () => {
            this.scrollbar.setPosition(tweener.x, 0)
            this.scrollbar.setMomentum(0, 0)
          },
        })
      }
    }

    @Watch('bounding', { deep: true })
    resize() {
      const width = (this.bounding.scene.x / this.bounding.scene.y) * this.viewport.height
      const height = this.viewport.height

      this.size.set(width, height)

      gsap.set(this.scrollbar.contentEl, { width, height })
    }

    syncScrollbar() {
      this.scrollbar.setPosition(-this.draggable.x, 0, { withoutCallbacks: true })
      this.scrollbar.setMomentum(0, 0)
      this.$emit('update', -this.draggable.x)
    }

    syncDraggable() {
      gsap.killTweensOf(this.scrollbar.contentEl)
      gsap.set(this.scrollbar.contentEl, { x: -this.scrollbar.offset.x })
      this.draggable.update()
    }

    smoothScroll() {
      this.$emit('update', this.scrollbar.offset.x)
      if (this.draggable) {
        this.syncDraggable()
      }
    }

    setup() {
      const x = (this.viewport.x - this.size.x) / 2
      this.scrollbar.update()
      this.scrollbar.setPosition(-x, 0)
      this.scrollbar.setMomentum(0, 0)
    }

    mounted() {
      const friction = this.$device.mobile ? 3 : 2
      const damping = this.$device.mobile ? 0.2 : 0.5

      this.scrollbar = new Scrollbar(this.$el, { renderByPixels: true, damping })
      this.scrollbar.updatePluginOptions('horizontalScroll', { friction })
      this.scrollbar.addListener(this.smoothScroll)

      this.$bus.$on(Events.GL.REVEAL, this.setup)

      if (!this.$device.mobile) {
        this.draggable = new Draggable(this.scrollbar.contentEl, {
          type: 'x',
          inertia: true,
          bounds: this.$el,
          edgeResistance: 1,
          dragResistance: 0.3,
          throwResistance: 2500,
          onDrag: () => this.syncScrollbar(),
          onThrowUpdate: () => this.syncScrollbar(),
        })
      }
    }

    destroyed() {
      this.$bus.$off(Events.GL.REVEAL, this.setup)
      this.scrollbar.removeListener(this.smoothScroll)
      this.scrollbar.destroy()
      if (this.draggable) {
        this.draggable.kill()
      }
    }
  }
